<template>
  <master-layout
    smallTitle="i miei"
    bigTitle="Clienti"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <ion-row class="cta ion-justify-content-center ion-padding-top"
      ><ion-col size="auto">
        <button
          class="p-d button-primary"
          @click="openModalNewClient"
        >
          Aggiungi cliente +
        </button>
      </ion-col>
    </ion-row>
    <ion-grid class="main-container">
      <ion-row class="searchbar">
        <ion-col>
          <ion-searchbar
            placeholder="Ricerca cliente esistente..."
            v-model="filter"
            autocomplete="on"
            clear-icon
            search-icon
          ></ion-searchbar>
        </ion-col>
      </ion-row>
      <FiltroClienti
        :showFiltroAgente="userData.role == 'Agente' ? false : true"
        @update:anagProspectFilters="handleFiltroCLientiChange"
        @reset:anagProspectFilters="handleFiltroClientiReset"
        @cerca:anagProspectFilters="handleFiltroClientiCerca"
      ></FiltroClienti>
      <h3>Lista Clienti</h3>
      <trCard
        @click="gotoDettagliCliente(item)"
        v-for="item of items"
        :key="item.id"
        cardType="white"
        class="mb-4"
        sideWidth="sm"
      >
        <template #main>
          <div class="flex flex-column">
            <span class="font-bold"
              ><span class="font-bold"> {{ item.ragioneSociale }} </span></span
            >
            <span>{{ item.nome }} {{ item.cognome }}</span>
          </div>
        </template>
        <template #right-side>
          <div
            class="w-full h-full flex flex-column align-items-center ion-justify-content-center"
          >
            <ion-icon
              style="font-size: 20px"
              :icon="enter"
            ></ion-icon>
            <span class="mt-1">Apri</span>
          </div>
        </template>
      </trCard>
    </ion-grid>
    <ion-row class="cta ion-justify-content-center"
      ><ion-col size="auto">
        <button
          :disabled="loading"
          type="button"
          class="p-d button-primary"
          @click="askForMoreResults"
        >
          {{ loading ? "Caricamento..." : "Carica più risultati" }}
        </button>
      </ion-col>
    </ion-row>
  </master-layout>
</template>

<script>
  import {
    modalController,
    IonButton,
    IonFooter,
    IonToolbar,
    IonSearchbar,
    IonCol,
    IonGrid,
    IonRow,
    IonIcon,
  } from "@ionic/vue";
  import { enter } from "ionicons/icons";
  import ModalNewAzienda from "./Modals/ModalNewAzienda.vue";
  import ModalActualClient from "./Modals/ModalActualClient.vue";
  import trCard from "@/components/Nuovi/trCard.vue";
  import ShoppingCartService from "../common/mixins/ShoppingCart.vue";
  import ApiService from "../common/service.api";
  import FiltroClienti from "@/components/Nuovi/FiltroClienti.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "AnagraficaClienti",
    components: {
      IonButton,
      IonFooter,
      IonToolbar,
      IonSearchbar,
      IonCol,
      IonGrid,
      IonRow,
      IonIcon,
      trCard,
      FiltroClienti,
    },
    mixins: [ShoppingCartService],

    data() {
      return {
        enter,
        filter: "",
        items: [],

        INFOPOPOVER_MAP: {
          Yoube:
            "Qui trovi tutti i dati relativi ai tuoi clienti : informazioni utili sul loro interesse verso il brand, e uno storico dei contatti che avete avuto. Da qui potrai contattarli direttamente fissando appuntamenti, inviando mail, messaggi o documenti.",
          default:
            "Qui trovi tutti i dati relativi ai tuoi clienti : informazioni utili sul loro interesse verso il brand, e uno storico dei contatti che avete avuto. Da qui potrai contattarli direttamente fissando appuntamenti, inviando mail, messaggi o documenti.",
        },
        currentResults: 0,
        loading: false,
        currentFiltroClienti: {
          regione: null,
          provincia: null,
          citta: null,
          agente: null,
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      handleFiltroClientiCerca() {
        this.currentResults = 0;
        this.getData();
      },
      handleFiltroClientiReset() {
        this.currentResults = 0;
        this.filter = "";
      },
      handleFiltroCLientiChange(filtro) {
        this.currentFiltroClienti = filtro;
      },
      getData() {
        this.loading = true;
        ApiService.post("Ecommerce/GetMyAnags/", {
          take: 10,
          skip: this.currentResults,
          ricerca: this.filter ? this.filter : "",
          regione:
            this.currentFiltroClienti.regione === "all"
              ? ""
              : this.currentFiltroClienti.regione,
          provincia:
            this.currentFiltroClienti.provincia === "all"
              ? ""
              : this.currentFiltroClienti.provincia,
          citta:
            this.currentFiltroClienti.citta === "all"
              ? ""
              : this.currentFiltroClienti.citta,
          idAgente:
            this.currentFiltroClienti.agente !== null
              ? this.currentFiltroClienti.agente
              : 0,
        })
          .then((res) => {
            this.items = res.data;
          })
          .finally(() => {
            this.currentResults = this.items.length;
            this.loading = false;
          });
      },
      askForMoreResults() {
        const endpoint = "Ecommerce/GetMyAnags/";
        const queryParams = {
          take: 10,
          skip: this.currentResults,
          ricerca: this.filter ? this.filter : "",
          regione:
            this.currentFiltroClienti.regione === "all"
              ? ""
              : this.currentFiltroClienti.regione,
          provincia:
            this.currentFiltroClienti.provincia === "all"
              ? ""
              : this.currentFiltroClienti.provincia,
          citta:
            this.currentFiltroClienti.citta === "all"
              ? ""
              : this.currentFiltroClienti.citta,
          idAgente:
            this.currentFiltroClienti.agente !== null
              ? this.currentFiltroClienti.agente
              : 0,
        };

        this.loading = true;

        ApiService.post(endpoint, queryParams)
          .then((response) => {
            this.items = this.items.concat(response.data);
          })
          .finally(() => {
            this.currentResults = this.items.length;
            this.loading = false;
          });
      },

      async openModalNewClient() {
        const modal = await modalController.create({
          component: ModalNewAzienda,
          cssClass: "my-custom-class",
          componentProps: { aziende: this.aziende },
        });

        return modal.present();
      },

      gotoDettagliCliente(cliente) {
        console.log(
          "🚀 ~ file: AnagraficaClienti.vue:152 ~ gotoDettagliCliente ~ cliente:",
          cliente
        );

        this.$router.push({
          name: "DettagliCliente",
          params: {
            idCliente: cliente.id,
          },
        });
      },
    },
    computed: {
      ...mapGetters("user", {
        userData: "getUserData",
      }),
    },
  };
</script>

<style scoped>
  /* * {
  border: 1px solid purple;
} */

  /* .cta {
  padding: 2rem 0;
}

.cta ion-button {
  height: 4rem;
} */

  h3 {
    font-weight: bolder;
  }
</style>
